import {
  FREE_MSG,
  NAVIGATION,
  // TOILET_INSTALL,
  INSTALLATION_SERVICES,
  INSTALL_SERVCIE,
  COMPLIMENTARY,
  ADD_TO_CART,
} from "@/components/Shared/UpsellInstallServices/analytics/constant"
import { SUCCESS } from "@/constants/index"
import { calculateCartData, sanitizeTextForAnalytics } from "@/utils/helper"
import _isEmpty from "lodash/isEmpty"
import { store } from "@/store"

const getProductInfoForAnalytics = (
  cartProductData = {},
  index = -1,
  numberOfChilds = 0,
  isChildren = ""
) => {
  if (!_isEmpty(cartProductData)) {
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    const { site: { siteName = "n/a" } = {} } = page
    const brand = siteName.toLowerCase()
    const {
      desc = "",
      discountedPrice = "",
      price = "",
      globalOffer = "n/a",
      globalDiscount = 0,
      name: productTileName = "",
      category = "",
      discountPriceState = "regular price ",
      unitPrice = "n/a",
      color: productColor = "n/a",
      productCoupondiscount = 0,
      productCouponCode = "n/a",
      customerFacingSKU = "n/a",
      quantity = 0,
      image = "n/a",
      parentglobalPromotionPrice = "",
      itemType = "",
      productRoomType = "",
    } = cartProductData
    const productId =
      "k" === customerFacingSKU[0]?.toLowerCase()
        ? `${customerFacingSKU}`
        : `K-${customerFacingSKU}`
    const productInfo = {
      description: desc.toLowerCase(),
      frequency: "n/a",
      globalPromotionPrice: parentglobalPromotionPrice
        ? parentglobalPromotionPrice
        : discountedPrice > 0
        ? Number((price - 0).toFixed(2))
        : 0,
      globalOffer: globalOffer,
      globalDiscount: globalDiscount.toFixed(2),
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      priceState: discountPriceState,
      productBasePrice: unitPrice,
      productCategory: category.toLowerCase(),
      productColor: productColor.toLowerCase(),
      productCoupondiscount,
      productCouponCode,
      productID: productId,
      productName:
        sanitizeTextForAnalytics(productTileName?.toLowerCase()) ||
        desc.toLowerCase() ||
        category.toLowerCase() ||
        "n/a",
      productPartnerBuyNow: "n/a",
      productRoom: productRoomType ? productRoomType.toLowerCase() : "service",
      productSalePrice: unitPrice,
      productSaleable: true,
      productStatus: "in stock",
      productSuperSku: false,
      productTileName: sanitizeTextForAnalytics(productTileName?.toLowerCase()),
      quantity,
      defaultImageName: image,
      ratings: "n/a",
      numberOfReviews: "n/a",
      pdpType: "regular finished goods & service parts",
      productFindingMethod: "n/a",
      productCollectionsName: category.toLowerCase(),
      productBrand: brand.toLowerCase(),
      itemType: itemType ? itemType.toLowerCase() : "service",
      category: category.toLowerCase(),
    }
    if (index > -1) {
      productInfo.productRecommendationType = `${
        !isChildren ? "parent" : "child"
      }|${index + 1}(${numberOfChilds})`
    }
    return productInfo
  }
  return false
}

const getrequiredProductInfo = (
  productinfo = {},
  childindex = -1,
  index = -1,
  persona = "",
  qty = 1,
  isParent = ""
) => {
  const {
    ProductDescriptionProductShort_s: decs = "",
    [`priceList.${persona}.discountedPrice_d`]: discountedPrice = "0",
    [`priceList.${persona}.price_d`]: productBasePrice = 0,
    CustomerFacingBrand_s: brandName = "",
    Product_Category: productCategory = "",
    RegionBrandName_s: Name = "",
    availableQuantity = 0,
    sku_s: skuid = "",
    discountPriceState = "regular price ",
    productCoupondiscount = 0,
    productCouponCode = "n/a",
    ["productImages.url_ss"]: productImage = [],
    [`priceList.${persona}.finalPrice_d`]: baseprice = 0,
    masterSKU_s: mastersku = "",
    unitPrice: unitPrice = "",
    productParentImage: productParentImage = "",
    productColor: productColor = "default",
    parentglobalPromotionPrice = "",
    itemType = "",
    productRoomType = "",
    productPrice = "",
  } = productinfo
  const pageInfoDetails = window?.adobeDataLayer?.getState("page")
  const isPdp =
    pageInfoDetails?.pageInfo?.contentType &&
    pageInfoDetails?.pageInfo?.contentType === "pdp"
  const skuProductId = skuid ?? mastersku ?? ""
  const productId =
    "k" === skuProductId[0]?.toLowerCase()
      ? `${skuProductId}`
      : `K-${skuProductId}`

  const productInfo = {
    description: decs.toLowerCase(),
    frequency: "n/a",
    globalPromotionPrice: parentglobalPromotionPrice
      ? parentglobalPromotionPrice
      : discountedPrice > 0
      ? Number((baseprice - 0)?.toFixed(2))
      : productBasePrice,
    globalOffer: "n/a",
    globalDiscount: discountedPrice,
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountPriceState,
    productBasePrice: productBasePrice || unitPrice || baseprice,
    productCategory: productCategory.toLowerCase(),
    productColor: productColor.toLowerCase(),
    productCoupondiscount,
    productCouponCode,
    productID: productId,
    productName:
      Name.toLowerCase() ||
      decs.toLowerCase() ||
      productCategory.toLowerCase() ||
      "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: productRoomType ? productRoomType.toLowerCase() : "service",
    productSalePrice: productPrice || unitPrice || baseprice,
    productSaleable: true,
    productStatus: availableQuantity ? "in stock" : "out of stock",
    productSuperSku: false,
    productTileName: Name.toLowerCase() || "n/a",
    quantity: isPdp ? 1 : qty,
    defaultImageName: productParentImage || productImage[0],
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: productCategory.toLowerCase(),
    productBrand: brandName.toLowerCase(),
    itemType: itemType ? itemType.toLowerCase() : "service",
    category: productCategory.toLowerCase(),
  }
  if (index > -1 && childindex > -1) {
    productInfo.productRecommendationType = `${isParent ? "parent" : "child"}|${
      index + 1
    }(${productinfo.foundIndex ? productinfo.foundIndex + 1 : childindex + 1})`
  }
  return productInfo
}

const getserviceproductData = (
  servicedata = {},
  serviceQTY = 1,
  index = "",
  isInstallService = "",
  qty = ""
) => {
  const {
    description = "",
    discountedPrice = 0,
    price = 0,
    name = "",
    customerBrand = "",
    productCategory = "",
    skuId = "",
    swatchUrl = "",
    images = "",
    quantity = 1,
  } = servicedata

  const productId = "k" === skuId[0]?.toLowerCase() ? `${skuId}` : `K-${skuId}`
  const isInstallServiceImage = isInstallService
    ? `${swatchUrl}${images}?$SellModalDesktop$`
    : ""
  const serviceData = {
    defaultImageName: isInstallService ? isInstallServiceImage : "n/a",
    description: description.toLowerCase(),
    frequency: "n/a",
    globalPromotionPrice: discountedPrice,
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    numberOfReviews: "n/a",
    pdpType: productCategory.toLowerCase(),
    priceState: "regular price",
    productBasePrice: price,
    productBrand: customerBrand.toLowerCase(),
    productCategory: productCategory.toLowerCase(),
    productCollectionsName: name.toLowerCase(),
    productColor: "n/a",
    productCouponCode: "n/a",
    productCoupondiscount: "n/a",
    productFindingMethod: "n/a",
    productID: productId ? productId : productCategory,
    productName: name.toLowerCase(),
    productPartnerBuyNow: "n/a",
    productRoom: productCategory.toLowerCase(),
    productSalePrice: discountedPrice,
    productSaleable: "true",
    productStatus: "in stock",
    productSuperSku: "false",
    productTileName: "installation services",
    quantity: qty ? qty : isInstallService ? quantity : serviceQTY,
    ratings: "n/a",
    productRecommendationType: `child|${index + 1}(1)`,
  }
  return serviceData
}
const addAnalyticsForToolTip = (
  servicename,
  title,
  url,
  cartProductData,
  recommendationTitle,
  upSellDataIndex = "",
  childProductIndex = "",
  isChildren = "",
  item = {},
  qty = 1,
  isCartAction = ""
) => {
  const services = servicename.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const recommended = recommendationTitle?.toLowerCase()
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const statusEvent = title == "add to cart" ? SUCCESS : "n/a"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${recommended}:${title}`,
    eventMsg: "n/a",
    eventName: `${pageload}:${recommended}:${title}`,
    eventStatus: statusEvent,
    eventType: title == "add to cart" ? "navigation" : "teaser",
    internalLinkName: title.toLowerCase(),
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${services}`,
    internalLinkURL: url ? url : "n/a",
    internalLinkZoneName: `${pageload}:${recommended}`,
  }
  if (item?.installServiceData?.discountedPrice && isCartAction) {
    eventInfo.upsellCartValueData =
      item?.installServiceData?.discountedPrice * qty
  }

  if (item?.installServiceData?.quantity) {
    item.installServiceData.quantity = qty
  }

  const serviceinfoDetails = getserviceproductData(
    item?.installServiceData,
    1,
    upSellDataIndex,
    1
  )

  const productInfoItems = [serviceinfoDetails]

  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}
const addAnalyticsForheader = (
  zipcode,
  title,
  recommendationTitle,
  serviceAvailableTitleUpSell
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const titleName = title.toLowerCase()
  const recommendation = recommendationTitle?.toLowerCase()
  if (titleName === "change zip code") {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `upsell products:${recommendation}:${titleName}`,
      eventMsg: "n/a",
      eventName: `${pageload}:${recommendation}:${titleName}`,
      eventStatus: "n/a",
      eventType: NAVIGATION,
      internalLinkName: titleName,
      internalLinkPosition: `${pageload}:upsell`,
      internalLinkType: `${pageload}:${zipcode}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${pageload}:${recommendation}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  } else {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `upsell products:${INSTALLATION_SERVICES.toLowerCase()}:${INSTALL_SERVCIE.toLowerCase()}:check availability`,
      eventMsg: "n/a",
      eventName: `${pageload}:${recommendation}:check availability`,
      eventStatus: SUCCESS,
      eventType: NAVIGATION,
      internalLinkName: "search",
      internalLinkPosition: `${pageload}:${recommendation}:upsell`,
      internalLinkType: `${pageload}:${zipcode}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${pageload}:${recommendation}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }
}

const addAnalyticsForFreeServices = (title = "", url = "") => {
  const titleInfo = title.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${FREE_MSG}: ${titleInfo}`,
    eventMsg: "n/a",
    eventName: `${pageload}:${FREE_MSG}:${titleInfo} `,
    eventStatus: "n/a",
    eventType: "teaser",
    internalLinkName: titleInfo,
    internalLinkPosition: `${pageload}:teaser`,
    internalLinkType: `${pageload}:${COMPLIMENTARY}`,
    internalLinkURL: url ? url : "n/a",
    internalLinkZoneName: `${pageload}:${COMPLIMENTARY}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

const addAnalyticsForArrows = (
  label = "",
  upsellData = [],
  index,
  persona,
  serviceAvailableTitleUpSell,
  recommendationTitle
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const type = label == "Next" ? "right" : "left"
  const title = upsellData?.requiredItems?.length
    ? recommendationTitle.toLowerCase()
    : serviceAvailableTitleUpSell.toLowerCase()
  const productInfoforCart = []
  const productInfoforRequiredItems = []
  const firstVisibleItem = upsellData ? upsellData : {}
  const {
    cartProductData = {},
    requiredItems = [],
    installServiceData = {},
  } = firstVisibleItem
  let installServiceInfo = ""
  if (!_isEmpty(installServiceData)) {
    installServiceInfo = {
      ["RegionBrandName_s"]:
        sanitizeTextForAnalytics(installServiceData?.name) || "",
      ["ProductDescriptionProductShort_s"]:
        installServiceData?.narrativeDescription,
      ["Product_Category"]: installServiceData?.productCategory,
      image: installServiceData?.productImageUrl,
      unitPrice: installServiceData?.price,
      customerFacingSKU: installServiceData?.customerFacingSKU,
      productTileName: installServiceData?.name,
      ["CustomerFacingBrand_s"]: installServiceData?.customerBrand,
      availableQuantity: installServiceData?.quantity,
      sku_s: installServiceData?.customerFacingSKU,
      productParentImage: `${installServiceData?.swatchUrl}${installServiceData?.images}?$SellModalDesktop$`,
      parentglobalPromotionPrice: installServiceData?.discountedPrice,
      productPrice: installServiceData?.discountedPrice,
    }
  }

  const requiredDataWithInstallServiceInfo = [
    ...(installServiceInfo ? [installServiceInfo] : []),
    ...requiredItems,
  ]

  const cartProductInfo =
    getProductInfoForAnalytics(
      cartProductData,
      index,
      requiredDataWithInstallServiceInfo.length
    ) ?? {}

  productInfoforCart.push(cartProductInfo)
  const requiredProductInfo = requiredDataWithInstallServiceInfo.map(
    (item, childindex) =>
      getrequiredProductInfo(item, childindex, index, persona) ?? []
  )
  productInfoforRequiredItems.push(requiredProductInfo)

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products: ${title}: ${type} arrow`,
    eventMsg: "n/a",
    eventName: `${pageload}:${title}: ${type} arrow`,
    eventStatus: "n/a",
    eventType: "carousel",
    internalLinkName: `${type} arrow`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${title}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:${title}`,
  }

  const productInfoItems = productInfoforCart.concat(
    productInfoforRequiredItems.flat(2)
  )
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}

const addAnalyticsForADDtoCart = (
  productinfo = {},
  servicechecked = "",
  servicedata = {},
  persona = "GST",
  serviceQTY = 1,
  subTotal = 0
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `cart preview: cart update: ${ADD_TO_CART}`,
    eventMsg: "n/a",
    eventName: `cart preview: cart update: ${ADD_TO_CART}`,
    eventStatus: "n/a",
    eventType: "naviagtion",
    internalLinkName: `add to cart`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${ADD_TO_CART}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:preview`,
    upsellCartValue: subTotal,
  }

  const arr = productinfo.map(item => {
    const { childindex = -1, index = -1, totalQTY = 1 } = item
    return getrequiredProductInfo(item, childindex, index, persona, totalQTY)
  })

  pushAnalyticsObjToDataLayer(eventInfo, arr)
}

const addAnalyticsWhenupsellvisible = (
  recommendation = "",
  productDataForAnlytics = {},
  persona = "",
  index = 0
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const recommendationtitle = recommendation.toLowerCase()
  const productInfoforCart = []
  const productInfoforRequiredItems = []
  const { parentProductData = {}, requiredItems = [] } = productDataForAnlytics
  const cartProductInfo =
    getProductInfoForAnalytics(parentProductData, 0, requiredItems.length) ?? {}
  productInfoforCart.push(cartProductInfo)

  const requiredProductInfo = requiredItems.map(
    (item, childindex) =>
      getrequiredProductInfo(item, childindex, 0, persona) ?? []
  )

  productInfoforRequiredItems.push(requiredProductInfo)

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${recommendationtitle}:product recommendation displayed `,
    eventMsg: "n/a",
    eventName: `upsell products:${recommendationtitle}:product recommendation displayed `,
    eventStatus: "n/a",
    eventType: "teaser",
    internalLinkName: `product recommendation displayed`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:product recommendation displayed`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:${recommendationtitle}`,
  }
  const productInfoItems = productInfoforCart.concat(
    productInfoforRequiredItems.flat(2)
  )
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}

const addAnalyticsForIncludeforInstallservice = (
  checked = false,
  included = "",
  recommendationTitle = "",
  installServiceData = {},
  index = ""
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const includetitle = included.toLowerCase()
  const recommendation = recommendationTitle.toLowerCase()
  const check = checked ? "select" : "remove"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell product:${recommendation}: ${includetitle} ${check}`,
    eventMsg: "n/a",
    eventName: `upsell product:${recommendation}: ${includetitle} ${check}`,
    eventStatus: "n/a",
    eventType: "teaser",
    internalLinkName: `${includetitle}:${check}`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${includetitle}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:${recommendation}`,
  }
  const serviceinfo = getserviceproductData(installServiceData, 1, index)
  pushAnalyticsObjToDataLayer(eventInfo, serviceinfo)
}

const addAnalyticsForIncludeforRequiredItem = ({
  checked = false,
  included = "",
  recommendationTitle = "",
  installServiceData = {},
  index = 1,
  childindex = -1,
  persona,
  isParent = false,
  qty = 1,
  recommendationSource = "n/a",
}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const categoryPageType = page?.category?.pageType
  const pageType = categoryPageType.toLowerCase() === "pdp" ? "pdp:" : ""
  const parentIndex = pageType ? 0 : index
  const includetitle = included.toLowerCase()
  const recommendation = recommendationTitle.toLowerCase()
  const check = checked ? "select" : "remove"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}upsell product:${recommendation}: ${includetitle} ${check}`,
    eventMsg: "n/a",
    eventName: `${pageType}upsell product:${recommendation}: ${includetitle} ${check}`,
    eventStatus: "n/a",
    eventType: "teaser",
    curatedRecommendation: recommendationSource,
    internalLinkName: `${includetitle}: ${check}`,
    internalLinkPosition: `${pageType ? pageType : `${pageload}:`}upsell`,
    internalLinkType: `${pageType ? pageType : `${pageload}:`}${includetitle}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${
      pageType ? pageType : `${pageload}:`
    }${recommendation}`,
  }
  const serviceinfo = getrequiredProductInfo(
    installServiceData,
    childindex,
    parentIndex,
    persona,
    qty,
    isParent
  )
  pushAnalyticsObjToDataLayer(eventInfo, serviceinfo)
}

const addAnalyticsForQuantitySelectorforinstallservice = (
  index = "",
  action = "",
  installServiceData = {},
  item = {},
  qty = 1
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const indextitle = action === "add" ? "addition" : "removal"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `cart preview: cart update: ${action}`,
    eventMsg: "n/a",
    eventName: `cart preview: cart update: ${action}`,
    eventStatus: "n/a",
    eventType: "naviagtion",
    internalLinkName: `cart update`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${indextitle}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:preview`,
  }

  const serviceinfoData = installServiceData
    ? getserviceproductData(installServiceData, 1, index, 1, qty)
    : null

  const productInfoItems = [serviceinfoData]
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}

const addAnalyticsForQuantitySelectorforRequiredproduct = (
  index = "",
  requiredproductData = {},
  parentIndex = -1,
  childindex = -1,
  persona,
  qty,
  cartProductData = {},
  installServiceDataAvailable = ""
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const indextitle = index === "add" ? "addition" : "removal"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `cart preview: cart update: ${index}`,
    eventMsg: "n/a",
    eventName: `cart preview: cart update: ${index}`,
    eventStatus: "n/a",
    eventType: "naviagtion",
    internalLinkName: `cart update`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${indextitle}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:preview`,
  }
  const serviceinfoData = requiredproductData
    ? getrequiredProductInfo(
        requiredproductData,
        childindex,
        parentIndex,
        persona,
        qty
      )
    : null
  const productInfoItems = [serviceinfoData]
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}
const addAnalyticsForPdpAddToCart = (
  subTotal = "",
  productDataForAnlytics = {},
  persona = "GST",
  recommendationSource = "n/a"
) => {
  const productInfoforCart = []
  const productInfoforRequiredItems = []
  const { parentProductDataAll = {}, selectedIncludePdpData = [] } =
    productDataForAnlytics
  const cartProductInfo =
    getProductInfoForAnalytics(
      parentProductDataAll ? parentProductDataAll : "",
      0,
      selectedIncludePdpData.length
    ) ?? {}
  if (cartProductInfo) {
    productInfoforCart.push(cartProductInfo)
  }
  const totalQTY = 1
  const requiredProductInfo = selectedIncludePdpData.map(
    item => getrequiredProductInfo(item, "", 0, persona, totalQTY) ?? []
  )

  productInfoforRequiredItems.push(requiredProductInfo)
  const price = Number(subTotal).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const isPdp = page?.category?.pageType
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${isPdp}:product details:upsell products:${ADD_TO_CART}`,
    eventMsg: "n/a",
    eventName: `${isPdp}:product details:upsell products:${ADD_TO_CART}`,
    eventStatus: "n/a",
    eventType: "naviagtion",
    internalLinkName: ADD_TO_CART,
    internalLinkPosition: `${isPdp}:upsell`,
    internalLinkType: `${isPdp}: ${ADD_TO_CART}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${isPdp}:product details`,
    upsellCartValue: price,
    curatedRecommendation: recommendationSource,
  }
  const productInfoItems = productInfoforCart.concat(
    productInfoforRequiredItems.flat(2)
  )
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}

const addAnalyticsPDPWhenUpSellVisible = ({
  recommendation = "",
  productDataForAnalytics = {},
  persona = "",
  index = 0,
  recommendationSource = "n/a",
}) => {
  const recommendationtitle = recommendation.toLowerCase()
  const productInfoforCart = []
  const productInfoforRequiredItems = []
  const { parentProductDataAll = {}, requiredItemsProductData = [] } =
    productDataForAnalytics
  const cartProductInfo =
    getProductInfoForAnalytics(
      parentProductDataAll,
      0,
      requiredItemsProductData.length
    ) ?? {}
  productInfoforCart.push(cartProductInfo)

  const requiredProductInfo = requiredItemsProductData.map(
    (item, childindex) =>
      getrequiredProductInfo(item, childindex, 0, persona) ?? []
  )

  productInfoforRequiredItems.push(requiredProductInfo)

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `pdp:upsell products:${recommendationtitle}:product recommendation displayed`,
    eventMsg: "n/a",
    eventName: `pdp:upsell products:${recommendationtitle}:product recommendation displayed`,
    eventStatus: "n/a",
    eventType: "teaser",
    curatedRecommendation: recommendationSource,
    internalLinkName: `product recommendation displayed`,
    internalLinkPosition: `pdp:upsell`,
    internalLinkType: `pdp:product recommendation displayed`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `pdp:${recommendationtitle}`,
  }
  const productInfoItems = productInfoforCart.concat(
    productInfoforRequiredItems.flat(2)
  )
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}

const pushAnalyticsObjToDataLayer = async (eventInfo = {}, cartProductData) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const cartObj = store?.getState()?.cart?.cart
  if (cartObj) {
    const { cart } = await calculateCartData({ cart: cartObj }, true)
    page.cart = cart
  }
  const productInfo = cartProductData || "null"
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
    productInfo,
  })
}

export {
  addAnalyticsForToolTip,
  addAnalyticsForheader,
  addAnalyticsForFreeServices,
  addAnalyticsForArrows,
  addAnalyticsForADDtoCart,
  addAnalyticsForIncludeforInstallservice,
  addAnalyticsForIncludeforRequiredItem,
  addAnalyticsForQuantitySelectorforinstallservice,
  addAnalyticsForQuantitySelectorforRequiredproduct,
  addAnalyticsWhenupsellvisible,
  addAnalyticsForPdpAddToCart,
  addAnalyticsPDPWhenUpSellVisible,
}
